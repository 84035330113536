import service from "../../../service/catalog.service";
import { mapState } from "vuex";
import bouncer from "../../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: "City",
  data() {
    return {
      path: "cities",
      confirmDeleteModal: false,
      processing: false,
      submitted: false,
      formDialog: false,
      filters: {},
      items: null,
      currentItem: null,
      layout: "list",
      countries: [],
    };
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {},
  mounted() {
    if (this.canAccess("ciudades_read")) {
      this.getData();
    }
  },
  methods: {
    saveItem() {
      this.submitted = true;
      if (
        !this.currentItem.name ||
        this.currentItem.name === "" ||
        this.currentItem.code === "" ||
        !this.currentItem.country
      ) {
        return;
      }
      this.processing = true;
      this.formDialog = false;
      this.currentItem.country_id = this.currentItem.country.id;
      service
        .save(this.path, this.currentItem, this.currentItem ? this.currentItem.id : undefined)
        .then(() => {
          this.getData();
          this.$toast.add({
            severity: "success",
            summary: "Registro guardado",
            detail: "Se guardó correctamente",
            life: this.$utils.toastLifeTime(),
          });
        })
        .catch((x) => {
          const message = x.response.data;
          let error = "";
          Object.keys(message).forEach((key) => {
            error += error + message[key] && message[key][0] ? message[key][0] + "\n" : "";
          });
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error,
            life: this.$utils.toastLifeTime(),
          });
        })
        .finally(() => {
          this.submitted = false;
          this.processing = false;
        });
    },
    hideDialog() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = false;
    },
    openNew() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = true;
    },
    getData() {
      this.processing = true;
      service
        .get(this.path)
        .then((x) => {
          this.items = x.data;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "se a producido un error",
            life: this.$utils.toastLifeTime(),
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    confirmDelete(data) {
      this.currentItem = data;
      this.confirmDeleteModal = true;
    },
    edit(item) {
      this.currentItem = item;
      this.submitted = false;
      this.formDialog = true;
    },
    deleteRow() {
      this.processing = true;
      this.confirmDeleteModal = false;
      service
        .delete(this.path, this.currentItem.id)
        .then(() => {
          this.currentItem = {};
          this.$toast.add({
            severity: "success",
            summary: "Registro eliminado",
            detail: "Se eliminó correctamente",
            life: this.$utils.toastLifeTime(),
          });

          this.getData();
        })
        .finally(() => {
          this.processing = false;
        });
    },
    getCatalogue(event, route, field) {
      service.getCatalog(event, route, field).then((x) => {
        this[route] = x;
      });
    },
  },
  computed: {
    ...mapState("auth", ["isAdmin"]),
  },
};
